import React, { useState, useEffect } from 'react';
import * as REQUEST from "../common/request";
import * as Utils from "../common/utils";

import './index.less';
import IMG_PINPAI_TITLE from './header.jpg';

const PageModel = props => {
	const [list, setList] = useState([]);
	const getList = async () => {
		const result = await REQUEST.get({
			url: '/h5page/v2/zhaoxiang/list'
		});
		if (!result) return;

		result.data.list.map(item => {
			item.key = item.id;
			return item;
		});
		setList(result.data.list);
	}
	useEffect(() => {
		getList();
		Utils.setPageTitle('赵巷镇新城一站大居');
		return () => false;
	}, [123]);

	return (
		<section className="app_zhaoxiang">
			{/* <div className="title" style={{ backgroundImage: `url(${IMG_PINPAI_TITLE})` }} /> */}
			<div className='content'>
				{
					list?.length > 0 && list.map((item,index)=>{
						return (
							<div key={index} className='btn_group' onClick={() => props.history.push(`/h5page/v2/zhaoxiang/detail/${item.id}`)}>{item.title}居委会</div>
						)
					})
				}
			</div>
		</section>
	);
};

export default PageModel;
