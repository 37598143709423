import React, { useState, useEffect } from 'react';
import ReactSwipe from 'react-swipe';
import * as REQUEST from "../common/request";
import * as Utils from "../common/utils";
import './index.less';
import { Result } from 'antd';

const PageModel = props => {
    let reactSwipeEl;
    console.log('props', props);
    const [swipeIndex, setSwipeIndex] = useState(0);
    const [data, setData] = useState(null);
    const [img, setImg] = useState([]);

    const getData = async () => {
        const { id } = props.match.params;
        const result = await REQUEST.get({
            url: '/h5page/v2/zhaoxiang/get',
            data: { id }
        });
        if (!result) return;

        setData(result.data);
        setImg(JSON.parse(result.data.img));
        if (result?.data?.title) {
            Utils.setPageTitle(result.data.title+'居委会');
        }
    };
    useEffect(() => {
        getData();
        return () => false;
    }, [props.match.params]);

    return (
        <section className='h5_detail'>
            <div className="banner-list-wrap">
            {
                img && img.length > 0 && <ReactSwipe
                    className="banner-list"
                    swipeOptions={{
                        continuous: false,
                        stopPropagation: false,
                        transitionEnd: setSwipeIndex
                    }}
                >
                    {
                        img.map((banner, idx) => <div className="banner-item" key={idx} style={{ backgroundImage: `url(${banner})` }} />)
                    }
                </ReactSwipe>
            }
            {
                img && img.length > 1 && <div className="index-wrap">
                    {img.map((p, idx) => <em key={idx} className={idx == swipeIndex ? 'active' : ''} />)}
                </div>
            }
        </div>
            {/* {img && img.length > 0 && img.map((item,index)=>{
                return <img className='img' key={index} src={item} />
            })} */}
            {data?.detail &&
                <div className='desc' dangerouslySetInnerHTML={{ __html: data?.detail }}></div>
            }
        </section>
    );
};

export default PageModel;
