/**
 * Created by jimmy on 2022/6/1.
 */

import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { AreaChartOutlined, MenuOutlined, BulbOutlined, MoneyCollectOutlined, ExperimentOutlined, AppstoreOutlined, SettingOutlined, LikeOutlined, MenuUnfoldOutlined, MenuFoldOutlined, PicCenterOutlined, BookOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';

import './index.less';
import * as REQUEST from "../../common/request";

const MENU = [
  {
    label: '公告管理', key: 'announce-mgr', icon: <AreaChartOutlined />,
    children: [
      { label: '公告列表', key: 'announce' },
      { label: '赵巷h5页面', key: 'zhaoxiangh5' }
    ]
  },
  {
    label: '商城管理', key: 'mall-mgr', icon: <AreaChartOutlined />,
    children: [
      { label: '门票管理', key: 'https://zjj.5james.com/admin' }
    ]
  },
  {
    label: '游船管理', key: 'cruise-mgr', icon: <AreaChartOutlined />,
    children: [
      { label: '预约班期管理', key: 'cruise' },
      { label: '预约订单', key: 'reserve-order' },
    ]
  },
  {
    label: '系统设置', key: 'admin-mgr', icon: <SettingOutlined />,
    children: [
      { label: '管理员设置', key: 'user' }
    ]
  },
];
const MENU_KEYS = (() => {
  const map = {};
  MENU.map(item => {
    item.children.map(citem => {
      map[citem.key] = item.key;
    })
  });
  return map;
})();

const CompModel = props => {
  const [collapsed, setCollapsed] = useState(false);

  const [ list, setList ] = useState([]);
  const getList = async (query = {}) => {
    const list = [ ...MENU ];
    setList(list);
  };
  useEffect(() => {
    getList();

    return () => false;
  }, []);

  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const [ openKeys, setOpenKeys ] = useState([]);
  useEffect(() => {
    const selectedKey = props?.match?.path?.split('/')[4] || (list[0]?.children[0]?.key || '');
    console.log('selectedKey', selectedKey)
    setSelectedKeys([selectedKey]);

    const openKey = MENU_KEYS[selectedKey];
    setOpenKeys([openKey]);
  }, [list]);

  return (
    <aside className="component-nav-container">
      <Menu {...{
        style: { flex: 1, overflow: 'auto', border: 0, width: collapsed ? 72 : 226 },
        mode: 'inline',
        theme: 'light',
        openKeys,
        selectedKeys,
        inlineCollapsed: collapsed,
        items: list,
        onClick: (data) => {
          if(data.key && data.key.startsWith('http')) {
            window.open(data.key);
          }else{
            setSelectedKeys([data.key]);
            props.history.push(`/zhujiajiao/v2/admin/${data.key}`);
          }
        },
        onOpenChange: openKeys => {
          setOpenKeys(openKeys);
        }
      }} />
      {/*<div className="collapsed" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? <MenuUnfoldOutlined style={{ fontSize: 16 }} /> : <MenuFoldOutlined style={{ fontSize: 16 }} />}
      </div>*/}
    </aside>
  );
};

export default CompModel;
